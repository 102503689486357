<template>
  <v-container>
    <v-card xs-12>
      <v-card-title class="title">
        <v-btn color="primary" @click.native="$router.push('/')" class="mr-3">
          <v-icon>home</v-icon>
        </v-btn>&nbsp; Test Data
      </v-card-title>
      <v-card-title></v-card-title>
      <v-divider></v-divider>

      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-select
                :items="mediaTypes"
                v-model="mediaType"
                label="Type"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="country"
                label="Country"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="lang"
                label="Language"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-select
                :items="providers"
                v-model="provider"
                label="Provider"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="age"
                label="Age"
              ></v-text-field>
            </v-col>
            
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="searchString"
                label="Search"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="genre"
                label="Genre"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="l"
                label="Limit"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-select
                :items="sortTypes"
                v-model="sortBy"
                label="Sorting"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="sortOrder"
                label="Sort Order"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <v-btn color="primary" @click.native="loadData" class="mr-3">
                <v-icon>search</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-divider></v-divider>

      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex v-for="item in results" v-bind:key="item._id" v-bind="{ [`xs1`]: true }">
            <v-card>
              <v-img :src="item.data.poster ? item.data.poster : item.data.poster_path" height="200px">
                <v-container fill-height fluid pa-2>
                  <!-- <v-layout fill-height>
                    <v-flex xs12 align-end flexbox>
                      <span class="headline white--text" v-text="item.data.seriesName"></span>
                    </v-flex>
                  </v-layout> -->
                </v-container>
              </v-img>
              <!-- <v-card-title>
                <p class="small white--text" v-text="item.data.seriesName"></p>
              </v-card-title> -->
              <v-card-subtitle>
                {{ item.data.firstAired }}
              </v-card-subtitle>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- <v-card
        v-for="item in results" v-bind:key="item._id"
        class="mx-auto"
        style="float: left;"
        max-width="200"
      >
        <v-img
          :src="item.data.poster"
          height="300px"
        ></v-img>

        <v-card-title>
          {{ item.data.seriesName }}
        </v-card-title>

        <v-card-subtitle>
         
        </v-card-subtitle>
      </v-card> -->

    </v-card>
  </v-container>
</template>

<script>
export default {
  methods: {
    loadData() {
      this.results = []
      let params = ''
      if (this.lang) params += '&lang='+this.lang
      if (this.l) params += '&l='+this.l
      if (this.lo) params += '&lo='+this.lo
      if (this.provider) params += '&provider='+this.provider
      if (this.genre) params += '&genre='+this.genre
      if (this.sortBy) params += '&sortBy='+this.sortBy
      if (this.country) params += '&country='+this.country
      if (this.searchString) params += '&search='+this.searchString
      if (this.age) params += '&age='+this.age
      if (this.sortOrder) params += '&sortOrder='+this.sortOrder

      this.$http.get('/api/'+this.mediaType+'?'+params.substr(1)).then(response => {
        this.results = response.data.data
      })
    }
  },
  created: function() {
  },
  watch: {
  },
  computed: {
  },
  data() {
    return {
      valid: true,
      mediaTypes: ['tvshows', 'movies'],
      sortTypes: ['', 'added', 'popular', 'rating', 'release'],
      providers: ['', 'netflix', 'disneyplus', 'amazonprime'],
      mediaType: 'movies',
      lang: 'us',
      l: 24,
      lo: 0,
      provider: '',
      genre: '',
      sortBy: '',
      sortOrder: 'asc',
      age: '',
      country: 'us',
      searchString: '',
      results: []
    };
  }
};
</script>

<style>
</style>
