import Vue from "vue";
import VueResource from "vue-resource";

Vue.use(VueResource);

Vue.http.options.credentials = true;

Vue.http.interceptors.push((request, next) => {
  request.headers = request.headers || {};
  next(response => {
    if (response.status === 401) {
      if (!window.location.pathname === "/login")
        window.location.pathname = "/login";
    }
  });
});


export const UserResource = Vue.resource("/api/user{/id}", {});
