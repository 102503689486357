<template>
  <v-container>
    <v-card xs-12>
      <v-card-title class="title">Home</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex v-for="card in cards" :key="card.title" v-bind="{ [`xs${card.flex}`]: true }">
              <v-card>
                <v-img :src="card.src" height="200px">
                  <v-container fill-height fluid pa-2>
                    <v-layout fill-height>
                      <v-flex xs12 align-end flexbox>
                        <span class="headline white--text" v-text="card.title"></span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-img>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon :to="card.route">
                    <v-icon>input</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  methods: {},
  created: function() {},
  watch: {},
  computed: {},
  data() {
    return {
      cards: [
        {
          title: "User",
          src: require("../assets/users.jpg"),
          route: "users",
          flex: 4
        },
        {
          title: "Test Data",
          src: require("../assets/users.jpg"),
          route: "testdata",
          flex: 4
        },
        {
          title: "EPG",
          src: require("../assets/epg.jpg"),
          route: "epg",
          flex: 4
        },
        // {
        //   title: "Firmware",
        //   src: require("../assets/firmware.jpg"),
        //   route: "Firmware",
        //   flex: 4
        // },
        // {
        //   title: "Message Log",
        //   src: require("../assets/messages.jpg"),
        //   route: "MessageLog",
        //   flex: 4
        // }
      ]
    };
  }
};
</script>

<style>
</style>
