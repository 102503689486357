import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      dark: {
        primary: "#E9BB3F",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c"
      },
    },
  }
});
