import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/Login'
import Home from '@/components/Home'
import Users from '@/components/Users'
import User from '@/components/User'
import TestData from '@/components/TestData'
import EPG from '@/components/EPG'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/users',
      name: 'Users',
      component: Users
    },
    {
      path: '/users/:id',
      name: 'User',
      component: User
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/testdata',
      name: 'Test Data',
      component: TestData
    },
    {
      path: '/epg',
      name: 'EPG',
      component: EPG
    }
  ]
})
