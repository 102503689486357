import filter from 'lodash/filter'

export default {
  data: function () {
    return {
      loggedIn: false,
      loggedInUser: {}
    }
  },

  methods: {
    getUser: function (callback) {
      this.$http.get('/api/myself').then(response => {
        this.loggedIn = true
        this.loggedInUser = response.body
        if (typeof callback === 'function') {
          callback(this.loggedInUser)
        }
      }, () => {
        this.loggedIn = false
        if (typeof callback === 'function') {
          callback(false)
        }
      })
    },
    logout: function () {
      this.$http.get('/api/logout').then(() => {
        this.loggedIn = false
        this.loggedInUser = {}
        //window.localStorage.removeItem("Authorization");
        location.reload()
      }, response => {
        if (response.body.code === "Unauthorized") {
          this.loggedIn = false
          //window.localStorage.removeItem("Authorization");
          location.reload()
        }
      })
    },
    authenticate: function (callback, username, password, domain) {
      this.$http.post('/api/auth', {username, password, domain}).then(() => {
        this.loggedIn = true
        //localStorage.setItem("Authorization", response.body.token)
        if (typeof callback === 'function') {
          callback(this.loggedIn)
        }
      }, () => {
        this.loggedIn = false
        if (typeof callback === 'function') {
          callback(this.loggedIn)
        }
      })
    } 
  },

  install: function (Vue, opts) {
    var _this = this;

    var self = new Vue(_this)

    Object.defineProperty(Vue.prototype, '$auth', {
      get () {
        return self
      }
    })

    let router = opts.router

    if (router) {
      router.beforeEach((to, from, next) => {
        self.getUser(function (response) {
          if (!response) {
            if (to.path == "/login") {
              next()
            } else {
              next("/login")
            }
          } else {
            if (to.path == "/login") {
              next("/")
            } else {
              next()
            }
          }
        }, function () {
          if (to.path == "/login") {
            next()
          } else {
            next("/login")
          }
        })
      })
    }
  },

  computed: {
    isAdmin: function () {
      var isAdmin = false
      filter(this.loggedInUser.roles, function (value) {
        if (value === 'admin') {
          isAdmin = true
        }
      })
      return this.loggedIn && isAdmin
    },
    isLoggedIn: function() {
      return this.loggedIn
    }
  }
}
