import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import store from './store'
import router from './router'
import Auth from './auth'
import VueResource from 'vue-resource'
import vuetify from './plugins/vuetify'
import Clipboard from 'v-clipboard'
import VuetifyConfirm from 'vuetify-confirm'

Vue.config.productionTip = false

Vue.use(VuetifyConfirm, {
  vuetify
})
Vue.use(Auth, {router})
Vue.use(Clipboard)
Vue.use(VueResource)
Vue.http.options.emulateJSON = true

new Vue({
  router,
  store,
  components: { App },
  template: '<App/>',
  render: h => h(App),
  vuetify,

  data: {
    emailRules: [
      v => {
        return !!v || 'E-Mail is required'
      },
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(v) || 'E-Mail must be valid'
    ],
    urlRules: [
      v => {
        return !!v || 'URL is required'
      },
      v => /^\w+([.-]?\w+)*$/.test(v) || 'URL must be valid'
    ],
    versionRules: [
      v => {
        return !!v || 'Version is required'
      },
      v => /^[1-9]+\.[0-9]+\.[0-9].*$/.test(v) || 'Version must be valid'
    ],
    uuidRules: [
      v => {
        return !!v || 'UUID is required'
      },
      v => /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(v) || 'Version must be valid'
    ]
  }
}).$mount('#app')
