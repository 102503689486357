<template>
  <v-container>
    <v-card xs-12>
      <v-card-title class="title">
        <v-btn color="primary" @click.native="$router.push('/')" class="mr-3">
          <v-icon>home</v-icon>
        </v-btn>&nbsp; Users
      </v-card-title>
      <v-card-title></v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="users"
        sort-by="username"
        class="elevation-1"
        :search="search"
        :loading="loading"
        :items-per-page="20"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-switch hide-details label="Unlock Delete" v-model="deleteUnlocked"></v-switch>
            <v-spacer></v-spacer>
            <v-text-field
              append-icon="search"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="goToUser(item)">edit</v-icon>
          <v-icon
            small
            v-if="deleteUnlocked"
            class="red--text"
            @click="deleteUser({item: item})"
          >delete</v-icon>
        </template>
      </v-data-table>

      <v-card-text>
        <v-btn absolute fab bottom right color="info" @click="createUserDialog=true, item = {}">
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>

    <v-dialog v-model="createUserDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Create a new User</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-form v-if="createUserDialog" style="width:100%;height:100%;" v-model="valid">
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    label="Username"
                    required
                    autofocus
                    v-model="createItem.username"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Email"
                    required
                    autofocus
                    v-model="createItem.email"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="blue--text" text @click.native="createUserDialog = false">Close</v-btn>
          <v-btn
            color="info"
            :disabled="!valid"
            @click.native="createUserDialog = false, createUser({item: createItem})"
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import clone from "lodash/clone";
import { mapActions, mapState } from "vuex";

export default {
  methods: {
    ...mapActions("user", ["getUsers", "createUser", "deleteUser"]),
    clone: clone,
    initData() {
      this.getUsers();
    },
    del(item) {
      this.item = clone(item);
      this.deletionDialog = true;
    },
    goToUser(item) {
      this.$router.push({ path: `/users/${item._id}` });
    }
  },
  created: function() {
    this.initData();
  },
  watch: {
    status(val) {
      this.loading = val === "fetching";
    },
    statusCreate(val) {
      this.loading = val === "fetching";
      if (val === "success") {
        this.initData();
        this.createItem = {};
      }
    },
    statusDelete(val) {
      this.loading = val === "fetching";
      if (val === "success") {
        this.initData();
      }
    },
    loading(val) {
      this.$store.state.loading = val;
    }
  },
  computed: {
    ...mapState({
      users: ({ user }) => user.users,
      status: ({ user }) => user.status,
      statusCreate: ({ user }) => user.statusCreate,
      statusDelete: ({ user }) => user.statusDelete
    })
  },
  data() {
    return {
      loading: false,
      valid: false,
      deleteUnlocked: false,
      search: "",
      headers: [
        {
          text: "Username",
          align: "left",
          sortable: true,
          value: "username"
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email"
        },
        {
          text: "Default Password",
          align: "left",
          sortable: true,
          value: "generated_password"
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "actions"
        }
      ],
      createUserDialog: false,
      tableData: [],
      item: {},
      createItem: {}
    };
  }
};
</script>

<style>
</style>
