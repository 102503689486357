<template>
  <v-app dark>
    <v-app-bar app>
      <v-toolbar-title class="headline text-uppercase">
        <span>SCIS</span>
        <span class="font-weight-light">Admin Panel</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text target="_blank" v-if="$auth.isLoggedIn" @click="$auth.logout">
        <span class="mr-2">Logout</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data () {
    return {}
  },
  computed: {
    username () {
      // We will see what `params` is shortly
      return this.$route.params.username
    }
  },
  methods: {
    goBack () {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/")
    }
  },
  created () {
    this.$vuetify.theme.dark = true
  }
};
</script>
