<template>
  <v-container>
    <v-card xs-12>
      <v-data-table
        :headers="headers"
        :items="results"
        :items-per-page="15"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="!item.epgService" small class="mr-2" @click="createService(item)">
            new_label
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Service</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="newService.name"
                  label="Service Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  :items="results"
                  v-model="channel"
                  item-text="name"
                  item-value="_id"
                  label="Channel"
                  @change="channelSelected"
                >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title><b>{{data.item.name}}</b> | Programs: <b>{{data.item.programCount}}</b> | {{data.item.url}}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="data">
                  <v-list-item-content>
                    <v-list-item-title><b>{{data.item.name}}</b> | <b>{{data.item.programCount}}</b></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-img
                  :src="channelDefaultIcon"
                  max-height="100"
                  max-width="100"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-img
                  :src="thumbnailPath ? thumbnailPath : `/api/file/${newService.icon}`"
                  max-height="256"
                  max-width="256"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  accept="image/*"
                  label="Upload a custom icon"
                  @change="thumbnailUpload"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveItem">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
   data() {
    return {
      results: [],
      search: '',
      dialog: false,
      selectedChannel: { name: null },
      newService: { name: null },
      channel: {},
      channelDefaultIcon: null,
      headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'External ID',
            align: 'start',
            sortable: true,
            value: 'externalId',
          },
          {
            text: 'Source',
            align: 'start',
            sortable: true,
            value: 'source',
          },
          {
            text: 'URL',
            align: 'start',
            sortable: true,
            value: 'url',
          },
          {
            text: 'Program Count',
            align: 'start',
            sortable: true,
            value: 'programCount',
          },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "actions",
          },
        ]
    }
  },
  methods: {
    loadData() {
      this.results = []

      this.$http.get('/api/epg/channel').then(response => {
        this.results = response.body.items
      })
    },
    createService(item) {
      this.selectedChannel = Object.assign({}, item)
      this.newService = { name: `PREFIX ${item.name}` }
      this.channel = item
      this.channelDefaultIcon = null
      this.channelSelected(item._id)
      this.newService.channel = item
      this.dialog = true
    },
    channelSelected (data) {
      const selectedChannel = this.results.find(x => x._id === data)
      this.channelDefaultIcon = selectedChannel.defaultIcon
    },
    close () {
      this.dialog = false
    },
    async saveItem () {
      const self = this
      this.newService.channel = this.channel

      this.$http.post('/api/epg/service', JSON.stringify(this.newService)).then((response, error) => {
          response.data.channel = self.channel
        self.channel.epgService = response.data._id
        self.close()
      }).catch ((error) => {
        
      })
    },
  },
  created: function() {
    this.loadData()
  },
  watch: {
  },
  computed: {
  }
}
</script>

<style>
</style>
