<template>
  <v-container>
    <v-card xs-12>
      <v-card-title class="title">
        <v-btn color="primary" @click.native="$router.push('/users')" class="mr-3">
          <v-icon>keyboard_backspace</v-icon> </v-btn
        >&nbsp; User - {{ user.username }}
      </v-card-title>
      <v-card-title></v-card-title>
      <v-divider></v-divider>

      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Username"
              required
              autofocus
              v-model="user.username"
              class="m-2"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Email"
              required
              autofocus
              v-model="user.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Password"
              required
              autofocus
              v-model="user.generated_password"
              disabled
              class="m-2"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
          </v-col>

          <v-col cols="12" sm="6">
            <v-btn color="primary" @click.native="save()" class="mr-3 float-right">
              <v-icon>save</v-icon> Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import clone from "lodash/clone";
import { mapActions, mapState } from "vuex";

export default {
  methods: {
    ...mapActions("user", ["readUser", "updateUser"]),
    clone: clone,
    initData() {
      this.readUser({ id: this.$route.params.id });
    },
    save() {
      this.updateUser({ item: this.user })
    }
  },
  created: function() {
    this.initData();
  },
  watch: {
    status(val) {
      this.loading = val === "fetching";
    },
    loading(val) {
      this.$store.state.loading = val;
    },
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user,
      status: ({ user }) => user.status,
      statusUpdate: ({ user }) => user.statusUpdate,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style></style>
