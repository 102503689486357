const REQUEST_USERS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_USERS = (state, { body }) => {
  state.status = "success";
  state.users = body;
};

const FAILURE_REQUEST_USERS = state => {
  state.status = "failure";
};

const REQUEST_USERS_EDIT = (state) => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_USERS_EDIT = (state) => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_USERS_EDIT = (state) => {
  state.statusUpdate = "failure";
}

const REQUEST_USERS_CREATE = (state) => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_USERS_CREATE = (state) => {
  state.statusCreate = "success";
};

const FAILURE_REQUEST_USERS_CREATE = (state) => {
  state.statusCreate = "failure";
}

const REQUEST_USERS_DELETE = (state) => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_USERS_DELETE = (state) => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_USERS_DELETE = (state) => {
  state.statusDelete = "failure";
}

const REQUEST_READ_USER = state => {
  state.statusRead = "fetching";
};

const SUCCESS_REQUEST_READ_USER = (state, { body }) => {
  state.statusRead = "success";
  state.user = body;
};

const FAILURE_REQUEST_READ_USER = state => {
  state.statusRead = "failure";
};

export default {
  REQUEST_USERS,
  SUCCESS_REQUEST_USERS,
  FAILURE_REQUEST_USERS,
  REQUEST_USERS_EDIT,
  SUCCESS_REQUEST_USERS_EDIT,
  FAILURE_REQUEST_USERS_EDIT,
  REQUEST_USERS_CREATE,
  SUCCESS_REQUEST_USERS_CREATE,
  FAILURE_REQUEST_USERS_CREATE,
  REQUEST_USERS_DELETE,
  SUCCESS_REQUEST_USERS_DELETE,
  FAILURE_REQUEST_USERS_DELETE,
  REQUEST_READ_USER,
  SUCCESS_REQUEST_READ_USER,
  FAILURE_REQUEST_READ_USER
};
