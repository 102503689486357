<template>
  <div style="margin-top:80px;">
    <h1 class="text-xs-center">Welcome</h1>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <v-text-field
          label="Username"
          v-model="username"
          required
          @keydown.enter="checkPW"
          autofocus
        ></v-text-field>
        <v-text-field
          label="Password"
          v-model="password"
          type="password"
          @keydown.enter="checkPW"
          required
        ></v-text-field>
        <v-btn @click="checkPW" @keydown.enter="checkPW">submit</v-btn>
        <v-btn @click="clear">clear</v-btn>
      </v-flex>
    </v-layout>
    <v-alert type="error" :value="error">Login not correct</v-alert>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      error: false,
      valid: false,
      username: "",
      password: ""
    };
  },
  methods: {
    checkPW() {
      var self = this;

      this.$auth.authenticate(
        function(success) {
          if (success) {
            self.$router.push({ path: "/" });
          } else {
            self.error = true;
          }
        },
        this.username,
        this.password
      );
    },
    clear() {
      this.user = "";
      this.password = "";
    }
  },
  watch: {}
};
</script>

<style scoped>
</style>