<template>
  <v-container>
    <v-card xs-12>
      <v-card-title class="title">
        <v-btn color="primary" @click.native="$router.push('/')" class="mr-3">
          <v-icon>home</v-icon> </v-btn
        >&nbsp; EPG
      </v-card-title>
      <v-card-title></v-card-title>
      <v-divider></v-divider>
      <div>
        <v-card class="d-flex justify-start mb-6" flat tile>
          <v-card>
            <v-navigation-drawer permanent style="width=300px !important;">
              <v-list dense rounded>
                <v-list-item
                  v-for="item in navigationItems"
                  :key="item.title"
                  link
                  @click.native="navigate(item.key)"
                >
                  <v-list-item-icon>
                    <v-icon
                      :style="visibility[item.key] ? 'color: orange;': ''">
                      {{ item.icon }}
                      </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title 
                      :style="visibility[item.key] ? 'color: orange;': ''">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-card>
          <v-card v-if="visibility.channels" width="100%">
            <Channels></Channels>
          </v-card>
          <v-card v-if="visibility.services" width="100%">
            <Services></Services>
          </v-card>
        </v-card>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Channels from "./epg/Channels.vue";
import Services from "./epg/Services.vue";

export default {
  data() {
    return {
      results: [],
      navigationItems: [
        { title: "Services", icon: "output", key: "services" },
        { title: "Input Channels", icon: "input", key: "channels" }
      ],
      visibility: {
        channels: false,
        services: true,
      },
    };
  },
  methods: {
    navigate(key) {
      for (const item in this.visibility) {
        this.visibility[item] = item === key;
      }
    },
    loadData() {
      this.results = [];

      this.$http.get("/api/").then((response) => {
        this.results = response.data.data;
      });
    },
  },
  created: function() {},
  watch: {},
  computed: {},
  components: {
    Channels,
    Services
  },
};
</script>

<style></style>
