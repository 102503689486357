import api from "@/api";

export default {
  async getUsers({ commit }) {
    commit("REQUEST_USERS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_USERS",
        await api.getUsers()
      );
    } catch (err) {
      commit("FAILURE_REQUEST_USERS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },

  async readUser({ commit }, { id }) {
    commit("REQUEST_READ_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_READ_USER",
        await api.readUser({id})
      );
    } catch (err) {
      commit("FAILURE_REQUEST_READ_USER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },

  async updateUser({commit}, { item }) {
    const id = item._id
    commit('REQUEST_USERS_EDIT')
    commit("SET_LOADING", true, { root: true });
    try {
      commit('SUCCESS_REQUEST_USERS_EDIT', await api.updateUser({id}, item))
    } catch (e) {
      commit('FAILURE_REQUEST_USERS_EDIT')
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },

  async createUser({commit}, { item }) {
    commit('REQUEST_USERS_CREATE')
    commit("SET_LOADING", true, { root: true });
    try {
      commit('SUCCESS_REQUEST_USERS_CREATE', await api.createUser(item))
    } catch (e) {
      commit('FAILURE_REQUEST_USERS_CREATE')
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },

  async deleteUser({commit}, { item }) {
    const id = item._id
    commit('REQUEST_USERS_DELETE')
    commit("SET_LOADING", true, { root: true });
    try {
      commit('SUCCESS_REQUEST_USERS_DELETE', await api.deleteUser({id}, item))
    } catch (e) {
      commit('FAILURE_REQUEST_USERS_DELETE')
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
