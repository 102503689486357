<template>
  <v-container>
    <v-card xs-12>
      <v-data-table
        :headers="headers"
        :items="results"
        :items-per-page="15"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn class="mt-3"
                @click.native="addService">
                Add new Service
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.icon`]="{ item }">
          <v-img
            v-if="item.icon"
            :src="`/api/file/${item.icon}`"
            max-height="100"
            max-width="100"
          ></v-img>
        </template>

        <template v-slot:[`item.lastFetch`]="{ item }">
          {{format_date(item.lastFetch)}}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            edit
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Service</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.name"
                  label="Service Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-autocomplete
                  :items="channels"
                  v-model="channel"
                  item-text="name"
                  item-value="_id"
                  label="Channel"
                  @change="channelSelected"
                >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title><b>{{data.item.name}}</b> | Programs: <b>{{data.item.programCount}}</b> | {{data.item.url}}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="data">
                  <v-list-item-content>
                    <v-list-item-title><b>{{data.item.name}}</b> | <b>{{data.item.programCount}}</b></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-img
                  :src="channelDefaultIcon"
                  max-height="100"
                  max-width="100"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-img
                  :src="thumbnailPath ? thumbnailPath : `/api/file/${editedItem.icon}`"
                  max-height="256"
                  max-width="256"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  accept="image/*"
                  label="Upload a custom icon"
                  @change="thumbnailUpload"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveItem">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      results: [],
      channels: [],
      channel: {},
      channelDefaultIcon: null,
      search: "",
      dialog: false,
      thumbnailPath: null,
      editedIndex: -1,
      editedItem: { name: null },
      headers: [
        {
          text: "UUID",
          align: "start",
          sortable: false,
          value: "_id",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Channel",
          align: "start",
          sortable: true,
          value: "channel.externalId",
        },
        {
          text: "Icon",
          align: "center",
          sortable: false,
          value: "icon",
        },
        {
          text: "Program Count",
          align: "start",
          sortable: true,
          value: "programCount",
        },
        {
          text: "Last Fetched",
          align: "start",
          sortable: true,
          value: "lastFetch",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYY/MM/DD hh:mm')
      }
    },
    loadData() {
      this.results = [];

      this.$http.get("/api/epg/service").then((response) => {
        this.results = response.body.items
      })

      this.$http.get("/api/epg/channel").then((response) => {
        this.channels = response.body.items
        // this.channels.forEach((channel) => {
        //   channel.name = `<b>${channel.name}</b> | ${channel.programCount} | ${channel.url}`
        // })
      })
    },
    editItem(item) {
      this.editedIndex = this.results.indexOf(item);
      this.editedItem = Object.assign({}, item)

      this.channel = item.channel
      this.dialog = true
    },

    async saveItem () {
      const self = this
      this.editedItem.channel = this.channel
      const selectedChannel = this.channels.find(x => x._id === this.channel)
      if (this.editedIndex !== -1) {
        this.results[this.editedIndex].channel = selectedChannel
      }

      if (this.editedItem._id) {
        this.$http.put('/api/epg/service/'+self.editedItem._id, JSON.stringify(this.editedItem)).then((response, error) => {
          self.results[this.editedIndex].icon = response.data.icon
          self.close()
        }).catch ((error) => {
          
        })
      } else {
        this.$http.post('/api/epg/service', JSON.stringify(this.editedItem)).then((response, error) => {
           response.data.channel = selectedChannel
          self.results.push(response.data)
          self.close()
        }).catch ((error) => {
          
        })
      }
    },
    
    channelSelected (data) {
      const selectedChannel = this.channels.find(x => x._id === data)
      this.channelDefaultIcon = selectedChannel.defaultIcon
      this.editedItem.channel = selectedChannel
      this.editedItem.name = `PREFIX ${selectedChannel.name}`
    },

    thumbnailUpload (data) {
      let reader = new FileReader
      reader.onload = e => {
        this.thumbnailPath = e.target.result
        this.editedItem.icon = reader.result
      }
      reader.readAsDataURL(data)
    },

    addService() {
      this.editedItem = { name: '' }
      this.dialog = true
    },

    deleteItem (item) {
      const self = this
      this.editedIndex = this.results.indexOf(item);
      const deleteItem = Object.assign({}, item)

      this.$confirm('Are you sure you want to delete this service?', {
        color: 'error',
        buttonTrueText: 'Yes',
        buttonFalseText: 'No',
        title: 'Confirm Deleteion'
      }).then((res) => {
        if (res) {
          this.$http.delete('/api/epg/service/'+deleteItem._id).then((response, error) => {
            self.results = self.results.splice(this.editedIndex, 1)
          }).catch ((error) => {
            
          })
        }
      })
    },

    close () {
      this.dialog = false
      this.editedItem = { name: null }
      this.channel = {}
      this.thumbnailPath = null
      this.editedIndex = -1
    }
  },
  created: function() {
    this.loadData()
  },
  watch: {},
  computed: {
  },
};
</script>

<style></style>
