import {
  UserResource,
} from "./resources";

export default {
  getUsers() {
    return UserResource.get();
  },
  readUser(id) {
    return UserResource.get(id);
  },
  updateUser(id, item) {
    return UserResource.update(id, item)
  },
  createUser(item) {
    return UserResource.save(item)
  },
  deleteUser(id, item) {
    return UserResource.remove(id, item)
  }
};
